const theme = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1440,
  },
  colors: {
    gray: '#595959',
    dimGray: '#666666',
    lightGrey: '#F5F5F5',
    lightBlue: '#bae7ff',
    primary: '#3353F4',
    primaryActive: '#2138cf',
    primaryHover: '#5e7eff',
    secondaryHover: '#E6F1FF',
    primaryLight: '#D9ECFE',
    secondary: '#F63C55',
    warning: '#CF1322',
    orange: '#ffa940',
    keyboardSelected: '#FFC069',
    defaultAnnotationColors: {
      0: '#ff7a45',
      1: '#fa8c16',
      2: '#ffd666',
      3: '#ffec3d',
      4: '#bae637',
      5: '#73d13d',
      6: '#36cfc9',
      7: '#40a9ff',
      8: '#597ef7',
      9: '#9254de',
      10: '#f759ab',
      11: '#ff4d4f',
    },
    backgroundPrimary: '#F9F9F9',
    backgroundSecondary: '#FFFFFF',
    borderLightGrey: '#F0F2F5',
    borderRegularGrey: '#d9d9d9',
    logoPrimary: '#2542A0',
    icon: '#BDBDBD',
    backgroundTag: '#2F80ED33',
    predictionIconPrimary: '#ed5857',
    predictionIconSecondary: '#333333',
    highlight: '#33333326',
    avatarSelected: '#828282',
    formLabel: '#999999',
    fontPrimary: '#262626',
    validateTextAreaButton: '#00000040',
    validateTextAreaButtonActive: '#000000',
  },
  shadows: {
    medium: '0 2px 8px rgba(0, 0, 0, 0.15)',
  },
  borders: {
    regular: '1px solid #d9d9d9',
    medium: '1px solid #828282',
    semiBold: '3px solid #828282',
    mediumSelected: '1px solid #000000',
    semiBoldSelected: '3px solid #000000',
  },
  radii: {
    regular: '2px',
  },
  fonts: {
    title: {
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '22px',
      fontSize: 20,
      color: '#000000',
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '22px',
      fontSize: 18,
      color: '#333333',
    },
    regular: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '24px',
      fontSize: 16,
      color: '#000000',
    },
    medium: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 14,
    },
    small: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 12,
    },
    superscript: {
      fontFamily: `'Scala Sans-Bold LF', sans-serif`,
      verticalAlign: 'super',
      textTransform: 'uppercase',
      fontSize: 10,
    },
  },
  transitions: {
    cubicBezier: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
  },
  zIndex: {
    MainSider: 100,
  },
}

module.exports = theme
